import React, { useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { getUser, userJoinBetaTest } from '../../../services/user/userService';
import { useAuth } from '../../../contexts/AuthContext';

export default function WaitingPage() {
  const { currentUser } = useAuth();
  const [betaClicked, setBetaClicked] = useState(false);

  const handleJoinBeta = async (currentUser, response) => {
    if (!currentUser) return;
    try {
      await userJoinBetaTest(currentUser, response);
    } catch (error) {
      console.error("Error joining beta:", error);
    }
    setBetaClicked(true);
  }

  useEffect(() => {
    const fetchUser = async () => {
      if (!currentUser) return;
      const user = await getUser(currentUser);
      if (user && user.beta) {
        console.log('user is already in beta');
      } else {
        console.log('user is not in beta');
      }
    };

    fetchUser();
  }, [currentUser]);

  return (
    <div className='w-screen h-screen'>
      <div>
        <Player
          autoplay
          loop
          src="https://lottie.host/fb2f0bac-b7e3-4ed0-bc4a-e619698b4392/uMofF6tuvk.json"
          style={{ height: '350px', width: '500px' }}
          >
        </Player>
        <h1 className="font-bold text-6xl h-0" style={{ position: 'relative', top: '-250px' }}>Bienvenue !</h1>
      </div>
      <div>
        <p className="m-4">
          Qeel arrive bientôt ! Elle se fait encore une petite beauté en Bêta Test 💄.
        </p>
        <p>
          Si jamais tu désires donner ton avis et participer à créer le meilleur outil des étudiants,
          <br />Tu peux participer à la bêta test! 
        </p>
        <p className="font-bold m-4 mb-10">
          Bien sûr, en tant que Qeeler, tu auras droit à 50% à vie sur l'application !
        </p>
        <div className="flex w-160 m-auto mb-10 justify-center">
        {!betaClicked &&  <div className="m-2 sm:m-auto p-1 max-w-144 rounded-full bg-rainbow hover:shadow-md">
          <div className="bg-white rounded-full font-bold p-2 cursor-pointer" onClick={() => handleJoinBeta(currentUser, true)}>
            Oui, je veux faire parti de la beta
          </div>
        </div>}
        {!betaClicked && <div className="underline m-2 font-bold sm:m-auto p-2 max-w-144 cursor-pointer" onClick={() => handleJoinBeta(currentUser, false)}>
          Non, je veux attendre la sortie officielle.
        </div> }
        { betaClicked && <div>
          <p>Merci pour ta réponse, et à très vite !</p>
        </div>}
        </div>
        <div>
          <img src="./qeel.png" alt="Qeel" width="140" className="m-auto"/>
        </div>
      </div>
    </div>
  );
}
