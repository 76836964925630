// Surcouche de route, qui permet de rediriger l'utilisateur vers la page de connexion si il n'est pas connecté.

import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function PrivateRoute({ children }) {
  const { currentUser, loading } = useAuth();
  
  useEffect(() => {
    if (!currentUser && !loading) {
      console.log('No user detected');
    }
  }
  , [currentUser, loading]);

  if (loading) {
    return <div>Loading...</div>
  }
  
  if (!currentUser) {
    return <Navigate to="/signup" />;
  }

  return children;
}
