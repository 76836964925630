import React from 'react';
import { useAuth } from '../../contexts/AuthContext';

export default function Header() {
  const { currentUser } = useAuth();
  
  if (window.location.pathname === '/login' || window.location.pathname === '/signup') {
    return null;
  }
  

  return (
    <div className="header">
      <div className="flex justify-between">
        <div className="header-logo p-2">
          <img src="./qeel.png" alt="qeel" className='m-auto w-16'/>
        </div>
        <div className="flex flex-row py-4 pl-4">
          {currentUser ? <a className="mx-6" href="#"></a> : <a className="mx-6" href="#">Sign in</a>}
        </div>
      </div>
    </div>
  )
}