import { db } from '../../config/firebase';
import { getDocs, addDoc, collection, query, where, serverTimestamp } from 'firebase/firestore';

export async function userJoinBetaTest(currentUser, response) {
  const userRef = collection(db, 'users');
  const q = query(userRef, where('uid', '==', currentUser.uid));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    const user = {
      uid: currentUser.uid,
      email: currentUser.email,
      betaTest: response,
      createdAt: serverTimestamp(),
    };
    await addDoc(userRef, user);
  }
  else {
    console.log('User already exists');
  }
}

export async function getUser(currentUser) {
  const userRef = collection(db, 'users');
  const q = query(userRef, where('uid', '==', currentUser.uid));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    console.log('No user found');
  }
  else {
    querySnapshot.forEach((doc) => {
      console.log(doc.id, ' => ', doc.data());
    });
  }
}