import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

export function useMessage() {
  return useContext(MessageContext);
}

export function MessageContextProvider({ children }) {
  const [conversationId, setConversationId] = useState(null);

  const updateConversationId = (id) => {
    setConversationId(id);
  }

  const contextValue = {
    conversationId,
    updateConversationId,
  }
  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  )
}