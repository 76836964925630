import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../config/firebase';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/soon');
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }

  return (
    <div className="w-screen">
        <img src="./qeel.png" alt="qeel" className='m-auto w-72'/>
      <div className="m-auto max-w-144 rounded-xl bg-rainbow p-2">
        <div className='bg-white p-10 rounded-lg'>
          <form className="flex flex-col items-center gap-8" action="SignUp" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4 justify-start w-64">
              <p className='text-left font-bold'>Email</p>
              <input className="border-2 p-2 rounded focus:outline-none" type="text" label="email" onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="flex flex-col gap-4 justify-start w-64">
              <p className='text-left font-bold'>Mot de passe</p>
              <input className="border-2 p-2 rounded focus:outline-none" type="password" label="Mot de passe" onChange={(e) => setPassword(e.target.value)}/>
            </div>
            {error && <p className="text-red-500">Email ou mot de passe non valide</p>}
            <button type="submit" className='px-10 py-4 bg-black text-white rounded hover:bg-slate-800 transition ease-linear'>Se Connecter</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
