import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/Pages/LoginPage/LoginPage';
import SignUpPage from './components/Pages/SignUpPage/SignUpPage';
import WaitingPage from './components/Pages/WaitingPage/WaitingPage';
import HomePage from './components/Pages/HomePage/HomePage';
import DocumentPage from './components/Pages/DocumentPage/DocumentPage';
import { AuthProvider } from './contexts/AuthContext';
import { MessageContextProvider } from './contexts/MessageContext';
import Header from './components/Header/Header';
import ProtectedRoute from './routes/ProtectedRoute';

import './App.css';
import './styles/index.css';

function App() {
  
  return (
    <div className="App">
      <AuthProvider>
        <MessageContextProvider>
          <Header />
          <Router>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/soon" element={<ProtectedRoute children={<WaitingPage />} />} />
              <Route path="/document" element={<ProtectedRoute children={<WaitingPage />} />} />
              <Route path="/document/:documentId" element={<ProtectedRoute children={<WaitingPage />} />} />
              <Route path="/" element={<ProtectedRoute children={<WaitingPage />} />} />
              <Route path="/*" element={<ProtectedRoute children={<WaitingPage />} />} />
            </Routes>
          </Router>
        </MessageContextProvider>
      </AuthProvider>
    </div>
  );
}

export default App;