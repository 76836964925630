import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading,  setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // L'utilisateur est connecté
        setCurrentUser(user);
        console.log('user is connected');
        setLoading(false);
      } else {
        // L'utilisateur n'est pas connecté
        setCurrentUser(null);
        console.log('user is not connected');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  console.log('loading', loading);
  console.log('currentUser', currentUser);
  const contextValue = {
    currentUser,
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}
